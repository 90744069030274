"use client";

import { useTranslations } from "next-intl"; 

function NotFound() {
  const t = useTranslations("NotFound");
  return (
    <div
      className=" flex w-screen max-w-screen-xl flex-col 
      items-start justify-start py-[170px] px-[40px] 2xl:px-0"
    >
      <p className="h1 mb-[30px]">{t("sorry_we_couldnt_find_that_page")}</p>
      <p className="mb-[32px] md:mb-[60px] text-[20px] md:text-[24px] max-w-[530px] font-degular-display-regular leading-[1.1]">
        {t(
          "the_page_you_are_looking_for_might_have_been_removed_has_its_name_changed_or_is_currently_unavailable"
        )}
      </p>
      <a href={"/"}>
        <button className=" font-bold text-[12px] md:text-[16px] rounded-[10px]
         text-white px-[32px] h-[48px] md:h-[52px] bg-black">
          {t("go_to_home_page")}
        </button>
      </a>
    </div>
  );
}

export default NotFound;
